import { Component, OnInit, HostListener, 
  ViewChild, ElementRef, Renderer2 } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TransferService } from './services/transfer.service';
import { IonContent} from '@ionic/angular';
import { Scroll } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public empresa = 'Adarga';
  public appPages = [
    {
      title: 'Quiz',
      url: '/folder/Quiz',
      icon: 'mail'
    },
    {
      title: 'CFA1',
      url: '/folder/cfa1',
      icon: 'heart'
    },
    {
      title: 'CFA2',
      url: '/folder/cfa2',
      icon: 'heart'
    },
    {
      title: 'CFA3',
      url: '/folder/cfa3',
      icon: 'heart'
    },
    {
      title: 'Valuation',
      url: '/folder/Valuation',
      icon: 'heart'
    },
    {
      title: 'Goals',
      url: '/folder/Goals',
      icon: 'heart'
    },
    {
      title: 'Cuali',
      url: '/folder/cuali',
      icon: 'heart'
    },
    {
      title: 'Search',
      url: '/folder/search',
      icon: 'heart'
    },
    {
      title: 'Oposiciones',
      url: '/folder/oposiciones',
      icon: 'heart'
    },
    {
      title: 'Leadership',
      url: '/folder/leader',
      icon: 'heart'
    },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  @ViewChild(IonContent)
  content:IonContent;
  

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private transfer: TransferService,
  ) {
    
    this.initializeApp();
    transfer.setSections(this.appPages);
    transfer.setSectionSelected(this.appPages[this.selectedIndex].title)
  
  }

  
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    console.log(this.appPages);
    if (path !== undefined) {
     
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
      console.log(this.selectedIndex);
    }

   
  }

  

  ngAfterViewInit() {
    
  }
}

