import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { QuizModule } from './quiz/quiz.module';
import { QuizComponent } from './quiz/quiz.component';
import { FinPageModule } from './fin/fin.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'folder/Quiz',
    pathMatch: 'full'
  },

  {
    path: 'folder/Quiz',
    loadChildren: () => import('./quiz/quiz.module').then( m => m.QuizModule)
  },
  {
    path: 'folder/Valuation',
    loadChildren: () => import('./analysis/analysis.module').then( m => m.AnalysisPageModule)
  },
  {
    path: 'folder/cfa1',
    loadChildren: () => import('./cfa1/cfa1.module').then( m => m.Cfa1PageModule)
  },
  {
    path: 'folder/Goals',
    loadChildren: () => import('./goals/goals.module').then( m => m.GoalsPageModule)
  },
  {
    path: 'folder/cfa2',
    loadChildren: () => import('./cfa2/cfa2.module').then( m => m.Cfa2PageModule)
  },
  {
    path: 'folder/cfa3',
    loadChildren: () => import('./cfa3/cfa3.module').then( m => m.Cfa3PageModule)
  },
  {
    path: 'folder/cuali',
    loadChildren: () => import('./cuali/cuali.module').then( m => m.CualiPageModule)
  },
  {
    path: 'folder/search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'folder/oposiciones',
    loadChildren: () => import('./oposiciones/oposiciones.module').then( m => m.OposicionesPageModule)
  },
  {
    path: 'folder/leader',
    loadChildren: () => import('./leader/leader.module').then( m => m.LeaderPageModule)
  },
  {
    path: 'cfa3',
    loadChildren: () => import('./cfa3/cfa3.module').then( m => m.Cfa3PageModule)
  },
  /*
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderModule)
  },
  */
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor() {
    console.log("AppRoutingModule");
  }
}
  
